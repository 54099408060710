
.react-horizontal-scrolling-menu--scroll-container {
    overflow-x: hidden !important;
}

.react-horizontal-scrolling-menu--arrow-left, .react-horizontal-scrolling-menu--arrow-right{
    align-items: center !important;
}

.grBOFc {
    justify-content: space-between !important;
}

.ksbXlf {
    overflow-x: visible !important;
}

.gBRlTK {
    background: #403c0e !important;
    width: 100%;
    border: none !important;
}

.bbmKKD {
    display: flex !important;
    justify-content: center !important;
}

.kFnrhu {
    display: flex !important;
    justify-content: center !important;
}

.hRjPiy {
    display: flex !important;
    justify-content: center !important;
}

.hfzgTF {
    display: flex !important;
    justify-content: center !important;
}

.bm-overlay{
    position: absolute !important;
    display: none !important;
}

.bm-menu-wrap{
    position: absolute !important;
    width: 100% !important;
    top: 0 !important;
    left: 0 !important;
}

.bm-item{
    display: flex !important;
}

.bm-burger-button{
    display: none !important;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.bqhzpp {
    display: flex !important;
    justify-content: center !important;
}

@import "~react-image-gallery/styles/css/image-gallery.css";

.image-gallery-icon:hover {
    color: #8B8000 !important;
}

.image-gallery-thumbnail:hover, .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus  {
    outline: none;
    border: 4px solid #8B8000;
}