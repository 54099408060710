
  .audio-player {
    background: #c1b6bc;
  }
  
  .inner {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* audio info */
  .audio-info {
    display: flex;
    gap: 20px;
  }
  
  .text {
    color: #8B8000;
  }
  
  /* audio image */
  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .audio-image {
    width: 300px;
    height: 300px;
    background: #1e1e1e; 
  }
  
  .audio-icon {
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background: #989898;
    margin-left: -5px;
  }
  
  .audio-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
  
  /* controls */
  .controls-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  
  .controls button, .volume button {
    border: none;
    background-color: transparent;
    margin-right: 8px;
    cursor: pointer;
  }
  
  button svg {
    font-size: 20px;
  }
  
  .volume {
    display: flex;
    align-items: center;
  }
  
  .volume button {
    margin: 0;
  }
  
  .progress {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  
  /* time */
  .time {
    color: #fff;
    font-size: 13px;
    line-height: 46px;
  }
  
  .time.current {
    color: #8B8000;
  }
  